<template>
    <template v-if="isLoading || row.isPropertiesLoading">
        <slot v-if="!hideLoadingIndicator" name="loading">
            <div class="d-md-flex justify-content-start col-12 mb-0 align-items-center">            
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span>{{ $t('Loading properties...') }}</span>
            </div>
        </slot>
    </template>
    <template v-else-if="!row.propertiesRowsArray?.length && !allProperties">
        <slot v-if="!hideNoProperties" name="noProperties">
            <div class="d-md-flex justify-content-start col-12 mb-0 align-items-center">            
                <span>{{ $t('Item has no properties') }} </span>
            </div>
        </slot>
    </template>
    <template v-else>
        <slot :properties="properties" :editor="Editor" :renderer="Renderer">
            <template v-if="editMode">
                <table class="table table-sm table-bordered mb-0" style="table-layout: fixed;font-size:80%">
                    <tbody>
                        <template v-for="(property, index) in filteredProperties" >
                            <tr v-if="showGroupHeader(index)" class="mb-1">
                                <td colspan="2" class="fw-bold">{{property.group}}</td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{property.caption}} 
                                    <slot name="afterCaption" :property="property">
                                        <template v-if="requiredField && row.propertiesRows[property.name][requiredField]">*</template>
                                    </slot>
                                </td>
                                <td style="width:50%">
                                    <PropertiesEditor :row="row.propertiesRows[property.name]" class="border-0 w-100" @blur="onBlur"
                                        v-model="row.properties[property.name]" :config="property" />
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table> 
                <slot name="bottom" :properties="filteredProperties">
                    <small class="ms-1" v-if="hasRequired" >{{$t("* Required")}}</small>
                </slot>
                <!-- <div v-if="propertyLookupDataObject" class="mt-2"> -->
                    <!-- <ODataLookup :dataObject="propertyLookupDataObject" -->
                        <!-- :bind="sel=>{ addNewObjProperty(sel) }"> -->
                        <!-- <template #target="scope" @click="scope.open"> -->
                            <!-- <button :ref="scope.target" type="button" class="btn btn-link p-0 mt-2 text-decoration-none"> -->
                                <!-- <i class="bi bi-plus-circle p-2">&nbsp;{{$t('Add Property')}}</i> -->
                            <!-- </button> -->
                        <!-- </template> -->
                        <!-- <template #toolbarActions> -->
                            <!-- <div class="form-check"> -->
                                <!-- <input class="form-check-input" :id="uid('lookup-restict')" type="checkbox" ref="showAllLkpRef" @click="emitToggleShowAll"> -->
                                <!-- <label class="form-check-label" :for="uid('lookuo-restrict')">{{ $t("Show all") }}</label> -->
                            <!-- </div> -->
                        <!-- </template> -->
                        <!-- <OColumn field="PropertyNameTranslated" width="300" :headerName="$t('Property Name')"/> -->
                        <!-- <OColumn field="DataType" width="110"/> -->
                        <!-- <OColumn field="Mandatory" width="110"/> -->
                    <!-- </ODataLookup> -->
                <!-- </div> -->
            </template>
            <template v-else >
                <template v-for="(property, index) in filteredProperties">
                    <h6 v-if="showGroupHeader(index)" class="mb-0 mt-2">{{property.group}}</h6>
                    <div class="d-md-flex justify-content-start col-12 mb-0">            
                        <span class="me-2 text-nowrap " :title="property.Caption">{{property.caption}}:</span>
                        <span><component :is="Renderer" :property="property"></component></span>
                    </div>
                </template>
            </template>
        </slot>
    </template>
</template>

<script setup lang="ts">
import type { DataItemModel } from 'o365.modules.DataObject.Types.ts';
import type { PropertiesDefintion } from 'o365.modules.DataObject.extensions.PropertiesData.ts';
import type DataObject from 'o365.vue.'

import 'o365.modules.DataObject.extensions.PropertiesData.ts';
import { getDataObjectById } from 'o365.vue.ts';
import ODataLookup from 'o365.vue.components.DataLookup.vue';
import PropertiesEditor from 'o365.vue.components.DataGrid.PropertiesEditor.vue';
import utils from 'o365.modules.utils.js';
import { ref, computed, watch, h } from 'vue';

const props = defineProps<{
    row: DataItemModel;
    allProperties?: boolean;
    /** Viewname on which the properties are configured */
    viewName?: string;
    /** Master bound field (from the viewName) */
    bindingField?: string;
    /** List of additional fields to load in from properties values view */
    additionalFields?: string[];
    /** When true will not show the 'No properties' element */
    hideNoProperties?: boolean;
    /** When true will not show the loading indicator */
    hideLoadingIndicator?: boolean;
    /** When true will render properties in an editable grid */
    editMode?: boolean;
    /** When true will save on editor blur */
    autosave?: boolean;
    /** WHen defined will add required indicator for properties  */
    requiredField?: string;
    /**
     * Compare function that will be called on loaded properties list. Used when you want to enforce 
     * custom sort order on properties.
     */
    sortFunction?: (a: PropertiesDefintion, b: PropertiesDefintion) => number;
    /**
     * Filter function for filtering out shown properties
     */
    filterFunction?: (pProperty: PropertiesDefintion, pValue: any) => boolean
}>();

const instanceId = window.crypto.randomUUID();
function uid(pId: string) {
    return `${pId}-${instanceId}`;
}

/** Get DataObject of the provided row */
function getDataObject() {
    if (props.row && props.row.dataObjectId && props.row.appId) {
        return getDataObjectById(props.row.dataObjectId, props.row.appId);
    } else {
        return undefined;
    }
}

const properties = ref([]);
const filteredProperties = computed(() => {
    if (props.filterFunction) {
        return properties.value.filter(property => {
            return props.filterFunction(property, props.row.properties[property.name]);
        });
    } else {
        return properties.value;
    }
});

const hasRequired = computed(() => {
    return props.requiredField && filteredProperties.value.some(property => props.row.propertiesRows[property.name][props.requiredField]);
});

const isLoading = ref(false);

async function initPropertiesForItem() {
    const dataObject = getDataObject();
    properties.value.splice(0, properties.value.length);
    if (dataObject == null) {
        return;
    }
    isLoading.value = true;
    try {

        dataObject.propertiesData.disableTracking = true;
        dataObject.propertiesData.initialize({
            viewName: props.viewName,
            bindingField: props.bindingField
        });
        dataObject.hasPropertiesData = true;
        await dataObject.propertiesData.initializationPromise;
        let additionalFields = new Set<string>();
        if (props.additionalFields) {
            props.additionalFields.forEach(field => additionalFields.add(field));
        }
        if (props.requiredField) {
            additionalFields.add(props.requiredField);
        }
        if (additionalFields.size > 0) {
            dataObject.propertiesData.setAdditionalFields(props.additionalFields);
        }

        dataObject.propertiesData.enable();

        const existingProperties = await dataObject.propertiesData.getExistingPropertiesForItem(props.row, props.allProperties);
        await dataObject.propertiesData.setProperties(existingProperties);
        properties.value.splice(0, properties.value.length);
        dataObject.propertiesData.selectedProperties.forEach(property => {
            const definition = { ...dataObject.propertiesData.propertiesDefinitions[property] };
            const name = definition.name;
            Object.defineProperty(definition, 'row', {
                get() {
                    return props.row.propertiesRows[name];
                }
            });
            properties.value.push(definition);
        });
        if (props.sortFunction) {
            properties.value.sort(props.sortFunction);
        } else {
            properties.value.sort((a, b) => a.group - b.group);
        }
    } catch (ex) {
        console.error(ex);
    } finally {
        isLoading.value = false;
    }
}

function showGroupHeader(pIndex: number) {
    const current = filteredProperties.value[pIndex]?.group;
    const previous = filteredProperties.value[pIndex - 1]?.group;
    return current != previous;
}

function onBlur() {
    if (props.autosave && props.row) {
        props.row.save();
    }
}

function Renderer(props2) {
    switch(props2.property.dataType) {
        case 'bool':
        return h('input', {
            type: 'checkbox',
            checked: props.row.properties[props2.property.name],
            disabled: true
        });
        case 'date':
            return utils.formatDate(props.row.properties[props2.property.name], 'Short Date')
        case 'datetime':
            return utils.formatDate(props.row.properties[props2.property.name], 'General Date Short Time')
        default:
            return props.row.properties[props2.property.name];
    }
}
Renderer.props = ['property'];



function Editor(props2, ctx) {
    return h(PropertiesEditor, {
        'row': props.row.propertiesRows[props2.property.name],
        'modelValue': props.row.properties[props2.property.name],
        'onUpdate:modelValue': newValue => props.row.properties[props2.property.name] = newValue,
        'config': props2.property
    }, ctx.slots.default
        ? {
            default: ({wrapper}) => ctx.slots.default({wrapper})
        }
        : undefined);
}
Editor.props = ['property'];

watch(() => props.row, () => {
    initPropertiesForItem();
});
initPropertiesForItem();

</script>